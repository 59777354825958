import { isEmpty } from 'lodash-es';

function normalize(title) {
  return title
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
}

function matchTitle(searchFilter, title) {
  const normalizedTitle = normalize(title);
  const normalizedFilter = normalize(searchFilter);

  return normalizedTitle.includes(normalizedFilter);
}

export function filterMaps(mapList, searchFilter) {
  if (isEmpty(mapList)) {
    return [];
  }
  if (!searchFilter) {
    return mapList;
  }

  return mapList.filter(map => matchTitle(searchFilter, map.title));
}
