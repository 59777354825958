async function checkError(response) {
  if (response.ok) {
    return;
  }
  const error = await response
    .clone()
    .json()
    .then(res => res.error)
    .catch(() => response.statusText);
  throw new Error(error);
}

export function makeHttpClient({ baseUrl }) {
  const headers = {
    'content-type': 'application/json',
  };

  async function request(url, method, options) {
    const response = await fetch(baseUrl + url, {
      method,
      headers,
      credentials: 'include',
      ...options,
    });
    await checkError(response);
    return response.json();
  }

  return {
    get(url) {
      return request(url, 'GET');
    },

    post(url, body) {
      return request(url, 'POST', {
        body: JSON.stringify(body),
      });
    },

    put(url, body) {
      return request(url, 'PUT', {
        body: JSON.stringify(body),
      });
    },

    delete(url, body) {
      return request(url, 'DELETE');
    },

    setAuth(username, password) {
      const credentials = btoa(`${username}:${password}`);
      headers.Authorization = `Basic ${credentials}`;
    },
  };
}
