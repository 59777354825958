import {
  EnvironmentOutlined,
  LogoutOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import style from './AppTemplate.module.css';
import { useCurrentUserQuery } from '../app/api';
import { ROUTES } from '../constants';

const menuItems = [
  {
    label: 'Maps',
    key: ROUTES.MAPS,
    icon: <EnvironmentOutlined />,
  },
  {
    label: 'Categories',
    key: ROUTES.CATEGORIES,
    icon: <TagsOutlined />,
  },
];

// returns first portion of a path (e.g. /maps/123 -> /maps)
function getPathPrefix(pathname) {
  return `/${pathname.split('/')[1]}`;
}

export function AppTemplate() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUserQuery = useCurrentUserQuery();
  if (currentUserQuery.isLoading) {
    return null;
  }
  if (!currentUserQuery.data) {
    return <Navigate to="/login" />;
  }
  const pathPrefix = getPathPrefix(pathname);
  return (
    <Layout style={{ height: '100vh' }}>
      <Layout>
        <Layout.Header>
          <Link to="/">
            <h1 className={style.Logo}>MapExplorer Admin</h1>
          </Link>
          <Menu
            selectedKeys={[pathPrefix]}
            onClick={({ key }) => navigate(key)}
            theme="dark"
            mode="horizontal"
            items={menuItems}
          />
          <Link to="/" style={{ float: 'right' }} reloadDocument>
            <Tooltip title={`Logout (${currentUserQuery.data.user.username})`}>
              <LogoutOutlined style={{ color: 'white', fontSize: 20 }} />
            </Tooltip>
          </Link>
        </Layout.Header>
        <Outlet />
      </Layout>
    </Layout>
  );
}
