import { pick } from 'lodash-es';

import { formImageFromUrl } from './common';

const COMMON_POI_PROPS = [
  'id',
  'x',
  'y',
  'title',
  'description',
  'url',
  'webUrl',
  'videoUrl',
  'categories',
  'phoneNumber',
];

export const apiPOIToFormPOI = apiPOI => ({
  ...pick(apiPOI, COMMON_POI_PROPS),
  image: [formImageFromUrl(apiPOI.imageUrl)],
});

export const formPOIToApiPOI = formPoi => ({
  ...pick(formPoi, COMMON_POI_PROPS),
  imageUrl: formPoi.image?.[0].url,
});
