import { Button, Form, Input, Modal, Space, message } from 'antd';
import {
  cloneDeep,
  isArray,
  isEqual,
  isNil,
  isString,
  omitBy,
} from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

import { MapImageEditor } from './MapImageEditor';
import { UnsavedChangesFooter } from './UnsavedChangesFooter';
import { useCreateMapMutation, useUpdateMapMutation } from '../../app/api';
import { handleError } from '../../app/errors';
import { validateImage } from '../../app/validateImage';
import { formImageFromUrl } from '../../utils/common';
import { apiPOIToFormPOI, formPOIToApiPOI } from '../../utils/poiUtils';
import { ImageUpload } from '../ImageUpload';
import { Toolbar } from '../Toolbar';

const MAX_PIXELS = 5750000;
const IMG_ERROR_MSG = `Resolution of image you are trying to upload is too high(width x height > ${MAX_PIXELS}), please try to use picture with lower resolution (E.g. 2395x2395)`;

const cleanEmptyProperties = value => {
  if (isNil(value)) {
    return true;
  }

  const shouldValueHaveLength = isString(value) || isArray(value);
  return shouldValueHaveLength && !value.length;
};

const sanitizePoi = poi => {
  const poiWithoutEmptyValues = omitBy(poi, cleanEmptyProperties);
  if (poi.image?.[0]?.url) {
    return poiWithoutEmptyValues;
  }

  poiWithoutEmptyValues.image = undefined;
  return poiWithoutEmptyValues;
};

const hasFormChanged = (apiData, formData) => {
  const initialDataToCompare = apiToForm(cloneDeep(apiData));
  const formDataToCompare = cloneDeep(formData);

  formDataToCompare.pois = formDataToCompare.pois?.map(sanitizePoi);
  initialDataToCompare.pois = initialDataToCompare.pois?.map(sanitizePoi);

  return !isEqual(formDataToCompare, initialDataToCompare);
};

const formToApi = ({ image, ean, pois, title }) => ({
  title,
  ean,
  imageUrl: image[0].url,
  pois: pois.map(formPOIToApiPOI),
});

const apiToForm = apiData => ({
  title: apiData?.title,
  ean: apiData?.ean,
  image: apiData?.imageUrl && [formImageFromUrl(apiData.imageUrl)],
  pois: apiData?.pois.map(apiPOIToFormPOI) || [],
});

export function MapForm({
  id,
  isCreating,
  data,
  form,
  poisFilter,
  currentPOI,
  setCurrentPOI,
}) {
  const navigate = useNavigate();
  const createMap = useCreateMapMutation();
  const updateMap = useUpdateMapMutation(id);
  const images = Form.useWatch('image', form);
  const image = images?.[0];

  const handleBlockerValidation = () => {
    if (isNavigatingAfterCreationRef.current) {
      isNavigatingAfterCreationRef.current = false;
      return false;
    }
    return hasFormChanged(data, form.getFieldsValue());
  };

  const blocker = useBlocker(handleBlockerValidation);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const isNavigatingAfterCreationRef = useRef();

  const onFinish = async formData => {
    try {
      const data = formToApi(formData);
      if (isCreating) {
        const createdMap = await createMap.mutateAsync(data);
        isNavigatingAfterCreationRef.current = true;
        navigate(`/maps/${createdMap.id}`);
      } else {
        await updateMap.mutateAsync(data);
      }
      message.success('Map has been saved, processing...');
      blocker.proceed?.();
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    const beforeUnloadHandler = e => {
      if (hasFormChanged(data, form.getFieldsValue())) {
        e.preventDefault();
      }
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () =>
      window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, [data, form]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        disabled={createMap.isLoading || updateMap.isLoading}
        initialValues={apiToForm(data)}
      >
        <Toolbar>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                disabled={saveButtonDisabled}
              >
                Save
              </Button>
              {data?.downloadUrl && (
                <Button
                  onClick={() =>
                    window.open(data.downloadUrl, '_blank').focus()
                  }
                >
                  Download
                </Button>
              )}
            </Space>
          </Form.Item>
        </Toolbar>

        <Form.Item name="pois" hidden />

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please input title' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="EAN" name="ean">
          <Input />
        </Form.Item>
        <Form.Item
          name="image"
          rules={[{ required: true, message: 'Please upload image' }]}
        >
          <ImageUpload
            maxCount={1}
            validationMethod={f => validateImage(f, MAX_PIXELS, IMG_ERROR_MSG)}
            showUploadList={false}
            onUploadStart={() => setSaveButtonDisabled(true)}
            onUploadFinish={() => setSaveButtonDisabled(false)}
            info="Image resolution is limited to 5 700 000 pixels (e.g. 2395x2395), please consider this while uploading the picture"
          />
        </Form.Item>
      </Form>
      {image && (
        <MapImageEditor
          image={image}
          form={form}
          poisFromApi={data?.pois}
          poisFilter={poisFilter}
          currentPOI={currentPOI}
          setCurrentPOI={setCurrentPOI}
        />
      )}
      {blocker.state === 'blocked' && (
        <Modal
          open
          title="Unsaved changes"
          onCancel={blocker.reset}
          footer={
            <UnsavedChangesFooter
              onCancel={blocker.reset}
              onLeaveWithSave={() => onFinish(form.getFieldsValue())}
            />
          }
        >
          <p>
            The map contains unsaved changes. If you leave the page, the changes
            will be discarded.
          </p>
        </Modal>
      )}
    </>
  );
}
