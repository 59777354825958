import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { handleError } from './app/errors';
import { AppTemplate } from './components/AppTemplate';
import { ROUTES } from './constants';
import { CategoriesPage } from './pages/CategoriesPage';
import { LoginPage } from './pages/LoginPage';
import { MapDetailPage } from './pages/MapDetailPage';
import { MapListPage } from './pages/MapListPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => handleError(error),
  }),
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/" element={<AppTemplate />}>
        <Route index element={<Navigate to={ROUTES.MAPS} replace />} />
        <Route path={ROUTES.MAPS} element={<MapListPage />} />
        <Route path={ROUTES.MAP} element={<MapDetailPage />} />
        <Route path={ROUTES.CATEGORIES} element={<CategoriesPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </>
  )
);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
