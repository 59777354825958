import { Space } from 'antd';
import { useState } from 'react';

import { useCategoriesQuery } from '../app/api';
import { CATEGORIES_TABLE_KEY } from '../app/tableState';
import { BasePageLayout } from '../components/BasePageLayout';
import { DataTable } from '../components/DataTable';
import { AdminButton } from '../components/EnsureRole';
import { Toolbar } from '../components/Toolbar';
import { AddCategoryModal } from '../components/category/AddCategoryModal';

const stringSortFn = prop => (a, b) => a[prop]?.localeCompare(b[prop]);

const columns = [
  {
    title: 'Label',
    dataIndex: 'label',
    sorter: stringSortFn('label'),
  },
  {
    title: 'Color',
    dataIndex: 'color',
    render: color => (
      <div
        style={{
          display: 'flex',
          width: '25px',
          height: '25px',
          backgroundColor: color,
          borderRadius: '50%',
        }}
      />
    ),
  },
];

export function CategoriesPage() {
  const categoriesQuery = useCategoriesQuery();
  const [modalVisible, setModalVisible] = useState(false);

  const hideModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);

  const rows = categoriesQuery.data?.data?.map(category => ({
    label: category.label,
    id: category.id,
    key: category.id,
    color: category.color,
  }));
  return (
    <BasePageLayout>
      <Toolbar>
        <Space>
          <AdminButton type="primary" onClick={showModal}>
            New
          </AdminButton>
        </Space>
      </Toolbar>
      <DataTable
        stateKey={CATEGORIES_TABLE_KEY}
        columns={columns}
        dataSource={rows}
        loading={categoriesQuery.isLoading}
        pagination={false}
      />
      {modalVisible && (
        <AddCategoryModal
          visible
          onOk={() => {
            hideModal();
            categoriesQuery.refetch();
          }}
          onCancel={hideModal}
        />
      )}
    </BasePageLayout>
  );
}
