import { Button, Collapse, Form, Select } from 'antd';
import { useState } from 'react';

import { useMapQuery, useMapsQuery } from '../../app/api';
import { handleError } from '../../app/errors';
import { useCopyPOI } from '../../hooks/useCopyPoi';

const OPTION_CUSTOM_FIELDS = { label: 'title', value: 'id' };

const DuplicateFormSelect = props => (
  <Select
    showSearch
    optionFilterProp="title"
    fieldNames={OPTION_CUSTOM_FIELDS}
    {...props}
  />
);

export const DuplicatePOIPicker = ({ onPrefill }) => {
  const copyPOI = useCopyPOI();
  const [loadingPOICopy, setLoadingPOICopy] = useState(false);

  const [form] = Form.useForm();
  const selectedMapId = Form.useWatch('mapId', form);
  const selectedPoiId = Form.useWatch('poiId', form);

  const mapsQuery = useMapsQuery();
  const mapQuery = useMapQuery(selectedMapId, {
    enabled: Boolean(selectedMapId),
  });

  if (!mapsQuery.data) {
    return null;
  }

  const { data: maps } = mapsQuery.data;
  if (!maps.length) {
    return null;
  }

  const poisOfSelectedMap = mapQuery.data?.pois;

  const handlePrefillRequest = async () => {
    setLoadingPOICopy(true);
    try {
      const selectedPoi = poisOfSelectedMap.find(
        poi => poi.id === selectedPoiId
      );
      const copiedPoi = await copyPOI(selectedPoi);
      onPrefill(copiedPoi);
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingPOICopy(false);
    }
  };

  const resetPOIIfMapChanged = changedValues => {
    if (!changedValues.mapId) {
      return;
    }

    form.setFieldValue('poiId', null);
  };

  return (
    <Collapse style={{ marginBottom: '1rem' }}>
      <Collapse.Panel key="1" header="Prefill data from another POI">
        <Form
          form={form}
          layout="vertical"
          name="poiDuplicateForm"
          onFinish={handlePrefillRequest}
          onValuesChange={resetPOIIfMapChanged}
        >
          <Form.Item name="mapId" label="Map">
            <DuplicateFormSelect options={maps} />
          </Form.Item>
          <Form.Item name="poiId" label="POI">
            <DuplicateFormSelect
              loading={mapQuery.loading}
              options={poisOfSelectedMap}
              disabled={mapsQuery.error || !selectedMapId}
            />
          </Form.Item>
          <Button
            type="primary"
            loading={loadingPOICopy}
            disabled={!selectedPoiId}
            onClick={form.submit}
          >
            Prefill data
          </Button>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
