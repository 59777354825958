import { Button } from 'antd';

export const UnsavedChangesFooter = ({ onCancel, onLeaveWithSave }) => (
  <>
    <Button onClick={onCancel}>Cancel</Button>
    <Button type="primary" onClick={onLeaveWithSave}>
      Save changes and leave
    </Button>
  </>
);
