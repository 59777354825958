import { Button, Checkbox, Typography } from 'antd';
import { difference, fromPairs, isNil, uniqBy } from 'lodash-es';
import { useEffect } from 'react';

import style from './sideMenu.module.css';

const constructCategoriesFilter = (categories, checked) =>
  categories.reduce((prev, curr) => {
    prev[curr.id] = checked;
    return prev;
  }, {});

const FilterOption = ({ label, color, checked, onChange }) => (
  <Checkbox
    style={{ color: `${color ?? 'white'}`, marginLeft: '8px' }}
    checked={checked}
    onChange={e => onChange(e.target.checked)}
  >
    {label}
  </Checkbox>
);

const getCategoriesForFilter = pois => {
  if (!pois.length) {
    return [];
  }

  const assignedCategoriesOfPois = pois
    .flatMap(poi => poi.categories)
    .filter(category => !isNil(category));

  const uniqueAssignedCategories = uniqBy(
    assignedCategoriesOfPois,
    category => category.id
  );
  uniqueAssignedCategories.sort((a, b) => a.label.localeCompare(b.label));

  return uniqueAssignedCategories;
};

const getJustAddedCategoryIds = (pois, poisFilter) => {
  const newCategories = getCategoriesForFilter(pois);
  const nextIds = newCategories.map(c => c.id);
  const prevIds = Object.keys(poisFilter.categories);
  const newIds = difference(nextIds, prevIds);
  return newIds;
};

const FilterButton = ({ label, ...props }) => (
  <Button type="primary" size="small" style={{ padding: '0 2px' }} {...props}>
    {label}
  </Button>
);

const EMPTY_POIS = [];

export const MenuPoiFilter = ({
  pois = EMPTY_POIS,
  poisFilter,
  setPoisFilter,
}) => {
  useEffect(() => {
    if (poisFilter) {
      return;
    }

    const initialPoiCategories = getCategoriesForFilter(pois);

    // initialize filter
    setPoisFilter({
      onlyNewlyCreatedPOIs: false,
      poisWithoutCategory: true,
      categories: constructCategoriesFilter(initialPoiCategories, true),
    });
  }, [pois, poisFilter, setPoisFilter]);

  useEffect(() => {
    if (!poisFilter) {
      return;
    }
    const justAddedCategories = getJustAddedCategoryIds(pois, poisFilter);
    if (!justAddedCategories.length) {
      return;
    }
    // auto-select new categories
    setPoisFilter(p => ({
      ...p,
      categories: {
        ...p.categories,
        ...fromPairs(justAddedCategories.map(id => [id, true])),
      },
    }));
  }, [pois, poisFilter, setPoisFilter]);

  if (!poisFilter) {
    return null;
  }

  const categoriesForFilter = getCategoriesForFilter(pois);

  const updateFilter = (
    onlyNewlyCreatedPOIs,
    poisWithoutCategory,
    categories
  ) =>
    setPoisFilter({
      onlyNewlyCreatedPOIs,
      poisWithoutCategory,
      categories,
    });

  const onCategoryFilter = (idOfCategory, checked) => {
    setPoisFilter(p => ({
      ...p,
      categories: {
        ...p.categories,
        [idOfCategory]: checked,
      },
    }));
  };

  const bulkFilterSelect = checked =>
    updateFilter(
      checked,
      checked,
      constructCategoriesFilter(categoriesForFilter, checked)
    );

  const setDefaultFilter = () =>
    updateFilter(
      false,
      true,
      constructCategoriesFilter(categoriesForFilter, true)
    );

  return (
    <div className={`${style.MenuFlexiblePart} ${style.MenuPartWrapper}`}>
      <Typography.Text strong style={{ color: 'white' }}>
        Filter:
      </Typography.Text>
      <div className={style.FilterOptionsWrapper}>
        <div
          style={{ display: 'flex', gap: '0.2rem', justifyContent: 'center' }}
        >
          <FilterButton label="Default" onClick={setDefaultFilter} />
          <FilterButton
            label="Unselect all"
            onClick={() => bulkFilterSelect(false)}
          />
          <FilterButton
            label="Only new"
            onClick={() => bulkFilterSelect(true)}
          />
        </div>
        <FilterOption
          label="Only new (unsaved) POIs"
          checked={poisFilter.onlyNewlyCreatedPOIs}
          onChange={checked =>
            setPoisFilter(p => ({ ...p, onlyNewlyCreatedPOIs: checked }))
          }
        />
        <ul className={style.CategoriesOptionsWrapper}>
          <li>
            <FilterOption
              label="Without category"
              checked={poisFilter.poisWithoutCategory}
              onChange={checked =>
                setPoisFilter(p => ({ ...p, poisWithoutCategory: checked }))
              }
            />
          </li>
          {categoriesForFilter.map(category => (
            <li key={category.id}>
              <FilterOption
                label={category.label}
                color={category.color}
                checked={poisFilter.categories[category.id]}
                onChange={checked => onCategoryFilter(category.id, checked)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
