import { useFileUploadUrl } from '../app/api';

export const useCopyImage = () => {
  const fetchFileUrls = useFileUploadUrl();

  const copyImage = async imageUrl => {
    const imageName = `copy-${imageUrl.split('/').pop()}`;
    const originalImage = await (await fetch(imageUrl)).blob();
    const imageCopy = new File([originalImage], imageName, {
      type: originalImage.type,
    });

    const urls = await fetchFileUrls(imageCopy);
    const response = await fetch(urls.uploadUrl, {
      method: 'PUT',
      body: imageCopy,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return urls.downloadUrl;
  };

  return copyImage;
};
