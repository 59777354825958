import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import style from './LoginPage.module.css';
import { useLoginMutation } from '../app/api';
import { handleError } from '../app/errors';

export function LoginPage() {
  const navigate = useNavigate();
  const login = useLoginMutation();

  const onFinish = async ({ username, password }) => {
    try {
      await login.mutateAsync({ username, password });
      navigate('/');
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      className={style.LoginForm}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input disabled={login.isLoading} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password disabled={login.isLoading} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" disabled={login.isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
