import { Tooltip, Typography } from 'antd';

import MapPinIcon from './MapPinIcon';

const { Text } = Typography;

const MAX_CONTAINER_WIDTH = 120;
const PIN_HEIGHT = 32;

function resolveCategoryColor(categories) {
  return categories?.[0]?.color ?? '#f44335';
}

function POIHover({ title, show, ...props }) {
  if (show) {
    return <Tooltip title={title} {...props} />;
  }
  return <div {...props} />;
}

export function POIMarker({
  poi,
  hiddenName,
  onMouseUp,
  onMouseDown,
  translucent,
}) {
  const opacity = translucent ? 0.5 : 1;
  const categoryColor = resolveCategoryColor(poi?.categories);

  return (
    <POIHover show={hiddenName} title={poi.title}>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: MAX_CONTAINER_WIDTH,
          top: poi.y - PIN_HEIGHT,
          left: poi.x - MAX_CONTAINER_WIDTH / 2,
          pointerEvents: 'none',
          opacity: opacity,
          alignContent: 'center',
        }}
      >
        <div
          onMouseUp={() => {
            onMouseUp && onMouseUp(poi);
          }}
          onMouseDown={e => {
            e.stopPropagation();
            onMouseDown(poi);
          }}
          style={{ pointerEvents: 'auto' }}
        >
          <div style={{ cursor: 'pointer' }}>
            <MapPinIcon fill={categoryColor} height={PIN_HEIGHT} />
          </div>
        </div>
        {poi.title && !hiddenName && (
          <Text
            mark
            style={{
              pointerEvents: 'auto',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={e => {
              e.stopPropagation();
              onMouseUp(poi);
            }}
            onMouseDown={e => {
              e.stopPropagation();
              onMouseDown(poi);
            }}
          >
            {poi.title}
          </Text>
        )}
      </div>
    </POIHover>
  );
}
