import {
  InfoCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Space, Spin, Tooltip, Upload, message } from 'antd';
import { useState } from 'react';

import { useFileUploadUrl } from '../app/api';
import { parseFileFromUrl } from '../utils/common';

const uploadingIcon = <LoadingOutlined spin />;

export function ImageUpload({
  onChange,
  value,
  onUploadStart,
  onUploadFinish,
  validationMethod,
  info,
  ...other
}) {
  const fetchUploadUrls = useFileUploadUrl();
  const [fileUrls, setFileUrls] = useState({});
  const [uploading, setUploading] = useState(false);

  async function beforeUpload(file) {
    try {
      validationMethod && (await validationMethod(file));
    } catch (error) {
      message.error(error.message);
      return false;
    }
  }

  async function simpleUploadRequest(options) {
    const { action, onSuccess, onError, file, method, headers } = options;

    // Disable upload and save buttons
    setUploading(true);
    onUploadStart();

    return fetch(action, { method, headers, body: file })
      .then(response => {
        if (response.ok) {
          onSuccess();
          message.success('Image uploaded successfully');
        } else {
          onError();
          message.error(
            `Error while uploading the image: ${response.status} ${response.statusText}`
          );
        }
      })
      .finally(() => {
        setUploading(false);
        onUploadFinish();
      });
  }

  async function uploadAction(file) {
    const urls = await fetchUploadUrls(file);
    setFileUrls({ ...fileUrls, [file.uid]: urls });
    return urls.uploadUrl;
  }

  return (
    <Upload
      onChange={info => {
        const fileList = info.fileList.map(file => {
          if (!file.status || file.status === 'error') {
            return {};
          }
          // put download url
          const url = fileUrls[file.uid]?.downloadUrl || file.url;
          // show new filename
          const name = parseFileFromUrl(url) || file.name;
          return { ...file, url, name };
        });
        onChange(fileList);
      }}
      fileList={value}
      action={uploadAction}
      customRequest={simpleUploadRequest}
      beforeUpload={beforeUpload}
      method="PUT"
      {...other}
    >
      {uploading ? (
        <Spin indicator={uploadingIcon} tip="Uploading file..." />
      ) : (
        <Space>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
          {info && (
            <Tooltip title={info}>
              <InfoCircleOutlined style={{ color: '#357EC7' }} />
            </Tooltip>
          )}
        </Space>
      )}
    </Upload>
  );
}
