import { Form, Layout, Spin } from 'antd';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';

import { useMapQuery } from '../app/api';
import { BasePageLayout } from '../components/BasePageLayout';
import { MapForm } from '../components/map/MapForm';
import { MapSideMenu } from '../components/map/MapSideMenu';
import { NEW_MAP_ID, ROUTES } from '../constants';

export function MapDetailPage() {
  const [currentPOI, setCurrentPOI] = useState(null);
  const [poisFilter, setPoisFilter] = useState(null);
  const [form] = Form.useForm();
  const detailRouteMatch = useMatch(ROUTES.MAP);
  const id = detailRouteMatch.params.id;
  const isCreating = id === NEW_MAP_ID;
  const mapQuery = useMapQuery(id, { enabled: !isCreating });

  if (mapQuery.isLoading && !isCreating) {
    return (
      <BasePageLayout>
        <Spin />
      </BasePageLayout>
    );
  }

  if (mapQuery.isError) {
    return (
      <BasePageLayout>
        <span>Error getting map detail</span>
      </BasePageLayout>
    );
  }

  return (
    <Layout>
      <MapSideMenu
        form={form}
        poisFilter={poisFilter}
        setPoisFilter={setPoisFilter}
        setCurrentPOI={setCurrentPOI}
      />
      <BasePageLayout>
        <MapForm
          id={id}
          form={form}
          data={mapQuery.data}
          isCreating={isCreating}
          poisFilter={poisFilter}
          currentPOI={currentPOI}
          setCurrentPOI={setCurrentPOI}
        />
      </BasePageLayout>
    </Layout>
  );
}
