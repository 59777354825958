export function parseFileFromUrl(url) {
  return url?.split('/').pop();
}

export function formImageFromUrl(url) {
  return {
    uid: url,
    url: url,
    name: parseFileFromUrl(url),
    status: 'done',
  };
}
