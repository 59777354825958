export const NEW_MAP_ID = 'new';

export const ROUTES = {
  LOGIN: '/login',
  MAPS: '/maps',
  MAP: '/maps/:id',
  CATEGORIES: '/categories',
};

export const ROLE = {
  ADMIN: 'admin',
  EDITOR: 'editor',
};
