function notImage(file) {
  return !file.type.match('image.*');
}

function validResolution(image, maxPixelCount) {
  const maxNotDefined = !maxPixelCount;
  const imagePixels = image.width * image.height;

  return maxNotDefined || imagePixels <= maxPixelCount;
}

export async function validateImage(file, maxRes, errMsg) {
  return new Promise((resolve, reject) => {
    notImage(file) && reject(new Error('Invalid file type'));
    const image = new Image();
    image.src = window.URL.createObjectURL(file);
    image.onload = () =>
      validResolution(image, maxRes) ? resolve() : reject(new Error(errMsg));
  });
}
