import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';

export default function SearchBar({ onSearch }) {
  const [searchText, setSearchText] = useState('');

  const handleTextChange = e => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <Input
      prefix={<SearchOutlined />}
      allowClear
      placeholder="Search map..."
      onChange={handleTextChange}
      value={searchText}
    />
  );
}
