import { Table } from 'antd';

import { saveTableState, useTableState } from '../app/tableState';

export function DataTable({ stateKey, columns, ...props }) {
  const state = useTableState(stateKey);

  const assignSort = column => {
    if (column.dataIndex === state?.sort?.field) {
      return { ...column, sortOrder: state?.sort.order };
    }
    return column;
  };

  const handleSave = (pagination, filter, sort) => {
    saveTableState(stateKey, { pagination, sort });
  };

  return (
    <Table
      onChange={handleSave}
      pagination={{ current: state?.page }}
      columns={columns.map(assignSort)}
      scroll={{ y: 'calc(100vh - 350px)' }}
      {...props}
    />
  );
}
