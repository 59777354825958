import { CopyOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import pLimit from 'p-limit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateMapMutation, useMapQuery } from '../app/api';
import { handleError } from '../app/errors';
import { useCopyImage } from '../hooks/useCopyImage';
import { useCopyPOI } from '../hooks/useCopyPoi';

export function CopyButton({ id }) {
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();
  const mapQuery = useMapQuery(id, { enabled: false });
  const copyPOI = useCopyPOI();
  const copyImage = useCopyImage();
  const createMap = useCreateMapMutation();

  const copyMap = async () => {
    try {
      setInProgress(true);
      const { data } = await mapQuery.refetch();
      const imageUrl = await copyImage(data.imageUrl);
      const limit = pLimit(3);
      const tasks = data?.pois.map(poi => limit(() => copyPOI(poi)));
      const pois = await Promise.all(tasks);

      const copy = {
        ...data,
        title: `copy of ${data.title}`,
        imageUrl: imageUrl,
        pois: pois,
      };

      const res = await createMap.mutateAsync(copy);
      navigate(`/maps/${res.id}`);
      message.success('Map has been copied, processing...');
    } catch (err) {
      handleError(err);
    } finally {
      setInProgress(false);
    }
  };

  if (inProgress) {
    return <Button shape="circle" icon={<LoadingOutlined />} />;
  }

  return (
    <Tooltip title="Copy map">
      <Button shape="circle" icon={<CopyOutlined />} onClick={copyMap} />
    </Tooltip>
  );
}
