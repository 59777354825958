import { Checkbox, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import useDimensions from 'react-use-dimensions';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import * as uuid from 'uuid';

import { POIEditModal } from './POIEditModal';
import { POIMarker } from './POIMarker';

const isPOISaved = (poi, savedPOIs) =>
  savedPOIs?.some(savedPoi => savedPoi.id === poi.id);

const doesPOIMatchFilter = (poi, savedPOIs, filterOptions) => {
  if (!filterOptions) {
    return true;
  }
  const { onlyNewlyCreatedPOIs, poisWithoutCategory, categories } =
    filterOptions;

  const newlyCreatedPOIsNoMatch =
    onlyNewlyCreatedPOIs && isPOISaved(poi, savedPOIs);
  if (newlyCreatedPOIsNoMatch) {
    return false;
  }

  const categoriesOfPoi = poi.categories ?? [];

  const noCategoriesMatch = poisWithoutCategory && !categoriesOfPoi.length;
  if (noCategoriesMatch) {
    return true;
  }

  return categoriesOfPoi.some(categoryOfPoi => categories[categoryOfPoi.id]);
};

function MapImage({ image, onNaturalDimensions }) {
  const initialized = useRef(false);
  return (
    <img
      src={image.url}
      alt={image.name}
      ref={ref => {
        if (initialized.current || !ref?.naturalWidth || !ref?.naturalHeight) {
          return;
        }
        initialized.current = true;
        onNaturalDimensions({
          width: ref.naturalWidth,
          height: ref.naturalHeight,
        });
      }}
    />
  );
}

function GestureContainer({
  children,
  scaleRef,
  onPointAdded,
  onPointMoved,
  onPointMoving,
  poiDragMode,
}) {
  const start = useRef();
  const selfRef = useRef();
  const MOVEMENT_LIMIT = 10;

  function cursorMoved(endX, endY) {
    const movementX = Math.abs(start.current.x - endX);
    const movementY = Math.abs(start.current.y - endY);

    return Math.max(movementX, movementY) > MOVEMENT_LIMIT;
  }

  function getNormalizedCoords(e) {
    const scale = scaleRef.current;
    const rect = selfRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / scale;
    const y = (e.clientY - rect.top) / scale;

    return { x, y };
  }

  return (
    <div
      ref={selfRef}
      style={{ position: 'relative' }}
      onMouseDown={e => {
        if (e.button === 0) {
          if (poiDragMode) {
            e.stopPropagation();
          } else {
            start.current = { x: e.clientX, y: e.clientY };
          }
        }
      }}
      onMouseUp={e => {
        if (e.button === 0) {
          if (!poiDragMode && !cursorMoved(e.clientX, e.clientY)) {
            onPointAdded(getNormalizedCoords(e));
          }
          poiDragMode && onPointMoved();
        }
      }}
      onMouseMove={e => {
        poiDragMode && onPointMoving(getNormalizedCoords(e));
      }}
    >
      {children}
    </div>
  );
}

export function MapImageEditor({
  image,
  form,
  poisFilter,
  poisFromApi,
  currentPOI,
  setCurrentPOI,
}) {
  const pois = Form.useWatch('pois', form);
  const scaleRef = useRef(1);
  const [imageDims, setImageDims] = useState();
  const [minScale, setMinScale] = useState(0.5);
  const [containerRef, { width, height }] = useDimensions();
  const [movingPOI, setMovingPOI] = useState(null);
  const [hiddenPOINames, setHiddenPOINames] = useState(false);

  useEffect(() => {
    if (!imageDims || !width || !height) {
      return;
    }
    const fullHeightScale = height / imageDims.height;
    const fullWidthScale = width / imageDims.width;
    const scale = Math.min(fullHeightScale, fullWidthScale);
    setMinScale(scale);
  }, [imageDims, width, height]);

  const filteredPois = pois?.filter(poi => {
    const poiIsCurrentPoi = poi.id === currentPOI?.id;
    if (poiIsCurrentPoi) {
      return false;
    }

    return doesPOIMatchFilter(poi, poisFromApi, poisFilter);
  });

  return (
    <div style={{ flex: 1, overflow: 'hidden' }} ref={containerRef}>
      <Checkbox
        style={{ padding: 5 }}
        checked={hiddenPOINames}
        onChange={() => setHiddenPOINames(!hiddenPOINames)}
        disabled={!pois?.length}
      >
        Hide POI names
      </Checkbox>
      <TransformWrapper
        minScale={minScale}
        onZoom={e => {
          scaleRef.current = e.state?.scale;
        }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <TransformComponent wrapperStyle={{ width, height }}>
            <GestureContainer
              poiDragMode={Boolean(movingPOI)}
              onPointAdded={point => {
                const { x, y } = point;
                const poi = { x, y, id: uuid.v4() };
                setCurrentPOI(poi);
              }}
              onPointMoving={point => {
                const { x, y } = point;
                const poi = { ...movingPOI, x, y };
                setMovingPOI(poi);
              }}
              onPointMoved={() => {
                setMovingPOI(null);
                setCurrentPOI(movingPOI);
              }}
              scaleRef={scaleRef}
            >
              {filteredPois?.map(poi => (
                <POIMarker
                  poi={poi}
                  key={poi.id}
                  hiddenName={hiddenPOINames}
                  onMouseUp={poi => {
                    setCurrentPOI(poi);
                  }}
                  onMouseDown={poi => {
                    setMovingPOI(poi);
                  }}
                />
              ))}
              {currentPOI && <POIMarker poi={currentPOI} />}
              {movingPOI && <POIMarker poi={movingPOI} translucent />}
              <MapImage image={image} onNaturalDimensions={setImageDims} />
            </GestureContainer>
          </TransformComponent>
        )}
      </TransformWrapper>
      {currentPOI && (
        <POIEditModal
          visible
          key={currentPOI?.id}
          poi={currentPOI}
          onCancel={() => setCurrentPOI(null)}
          onOk={data => {
            const poi = { ...currentPOI, ...data };
            const isExisting = pois.some(p => p.id === poi.id);
            const newPois = isExisting
              ? pois.map(p => (p.id !== poi.id ? p : poi))
              : pois.concat([poi]);
            form.setFieldsValue({ pois: newPois });
            setCurrentPOI(null);
          }}
          onDelete={() => {
            form.setFieldsValue({
              pois: pois.filter(p => p.id !== currentPOI.id),
            });
            setCurrentPOI(null);
          }}
        />
      )}
    </div>
  );
}
