import { Button, Form, Input, Modal, Space, message } from 'antd';
import { useState } from 'react';
import { CirclePicker } from 'react-color';

import { useCreateCategoryMutation } from '../../app/api';
import { handleError } from '../../app/errors';

export function AddCategoryModal({ visible, onCancel, onOk }) {
  const createCategory = useCreateCategoryMutation();
  const [color, setColor] = useState();
  const [form] = Form.useForm();

  const handleColorChange = colorChangeData => {
    setColor(colorChangeData.hex);
  };

  const onFinish = async formData => {
    try {
      const data = { label: formData.label, color: formData.color.hex };
      await createCategory.mutateAsync(data);
      message.success('Category has been saved');
      onOk();
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Modal
      title="New category"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Space>
          <Button key="cancel" type="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            key="ok"
            type="primary"
            onClick={() => form.submit()}
            disabled={createCategory.isLoading}
            loading={createCategory.isLoading}
          >
            OK
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="categoriesForm"
        onFinish={onFinish}
        disabled={createCategory.isLoading}
      >
        <Form.Item name="label" label="Label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="color" label="Color" rules={[{ required: true }]}>
          <CirclePicker color={color} onChangeComplete={handleColorChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
