import { Button, Tooltip } from 'antd';

import { useCurrentUserQuery } from '../app/api';
import { ROLE } from '../constants';

export function AdminButton({ text, disabled, ...others }) {
  const { data } = useCurrentUserQuery();
  const isNotAdmin = data?.user?.role !== ROLE.ADMIN;
  const isDisabled = isNotAdmin || disabled;

  return (
    <Tooltip title={isNotAdmin && 'missing permission'}>
      <Button disabled={isDisabled} {...others} />
    </Tooltip>
  );
}
