import { Breadcrumb, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import style from './BasePageLayout.module.css';
import { config } from '../app/config';

const { Content, Footer } = Layout;

const IS_PRODUCTION = config.stage === 'prod';
const FOOTER_BACKGROUND_COLOR = config.stage === 'dev' ? '#A6DE98' : '#f0f070';
const FOOTER_TEXT = `Map Explorer Admin${
  IS_PRODUCTION ? '' : ` - ${config.stage}`
}`;

function PageBreadcrumb() {
  const { pathname } = useLocation();
  const { items } = pathname
    .split('/')
    .slice(1)
    .reduce(
      (acc, part) => {
        const path = `${acc.lastPath}/${part}`;
        return {
          lastPath: path,
          items: [...acc.items, { label: part, path }],
        };
      },
      { lastPath: '', items: [] }
    );
  return (
    <Breadcrumb style={{ marginTop: 16 }}>
      {items.map(({ path, label }) => (
        <Breadcrumb.Item key={path}>
          <Link to={path}>{label}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export const BasePageLayout = ({ children }) => (
  <Layout>
    <Content className={style.Content}>
      <PageBreadcrumb />
      {children}
    </Content>
    <Footer
      style={{
        textAlign: 'center',
        ...(!IS_PRODUCTION && { backgroundColor: FOOTER_BACKGROUND_COLOR }),
      }}
    >
      {FOOTER_TEXT}
    </Footer>
  </Layout>
);
