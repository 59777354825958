import { Typography } from 'antd';
import { cloneDeep } from 'lodash-es';

import listStyle from './menuPoisList.module.css';
import style from './sideMenu.module.css';

const getSortedPOIs = pois => {
  const copiedPois = cloneDeep(pois);
  copiedPois.sort((a, b) => a.title.localeCompare(b.title));

  return copiedPois;
};

const PoiCategories = ({ categories }) => {
  if (!categories || !categories.length) {
    return null;
  }

  return (
    <ul style={{ listStyle: 'none', paddingLeft: '0.75rem' }}>
      {categories.map(category => (
        <li key={category.id} style={{ color: category.color }}>
          {category.label}
        </li>
      ))}
    </ul>
  );
};

const PoiInMenu = ({ poi, setCurrentPOI }) => (
  <li style={{ color: 'white' }}>
    <div
      style={{
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <button
        className={listStyle.PoiButton}
        onClick={() => setCurrentPOI(poi)}
      >
        {poi.title}
      </button>
      <PoiCategories categories={poi.categories} />
    </div>
  </li>
);

export const MenuPoisList = ({ pois, setCurrentPOI }) => {
  if (!pois?.length) {
    return null;
  }

  const sortedPois = getSortedPOIs(pois);

  return (
    <div className={`${style.MenuFlexiblePart} ${style.MenuPartWrapper}`}>
      <Typography.Text strong style={{ color: 'white' }}>
        List of POIs:
      </Typography.Text>
      <ul className={style.PoisList}>
        {sortedPois.map(poi => (
          <PoiInMenu key={poi.id} poi={poi} setCurrentPOI={setCurrentPOI} />
        ))}
      </ul>
    </div>
  );
};
