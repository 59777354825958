import { ReloadOutlined } from '@ant-design/icons';
import { Button, Space, message } from 'antd';
import _ from 'lodash-es';
import { useRef, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';

import { useBatchDeleteMapMutation, useMapsQuery } from '../app/api';
import { handleError } from '../app/errors';
import { filterMaps } from '../app/filterMaps';
import { MAPLIST_TABLE_KEY } from '../app/tableState';
import { BasePageLayout } from '../components/BasePageLayout';
import { CopyButton } from '../components/CopyButton';
import { DataTable } from '../components/DataTable';
import { AdminButton } from '../components/EnsureRole';
import SearchBar from '../components/SearchBar';
import { Toolbar } from '../components/Toolbar';
import { NEW_MAP_ID, ROUTES } from '../constants';

const numberSortFn = prop => (a, b) => a[prop] - b[prop];
const stringSortFn = prop => (a, b) => a[prop]?.localeCompare(b[prop]);

const bytesToMB = bytes => Math.round(bytes / 1000000);

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (text, record) => <Link to={`/maps/${record.id}`}>{text}</Link>,
    sorter: stringSortFn('title'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    render: isoDate => new Date(isoDate).toLocaleString(),
    sorter: stringSortFn('updatedAt'),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Size',
    dataIndex: 'size',
    render: size => size && `${bytesToMB(size)} MB`,
    sorter: numberSortFn('size'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: stringSortFn('status'),
  },
  {
    title: '',
    dataIndex: 'id',
    render: id => <CopyButton id={id} />,
  },
];

export function MapListPage() {
  const [searchText, setSearchText] = useState('');
  const mapsQuery = useMapsQuery();
  const filteredMaps = filterMaps(mapsQuery?.data?.data, searchText);
  const deleteMaps = useBatchDeleteMapMutation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSearch = useRef(
    _.debounce(text => setSearchText(text), 500, { leading: true })
  );

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const rows = filteredMaps.map(map => ({
    title: map.title,
    size: map.size,
    status: map.status,
    updatedAt: map.updatedAt,
    id: map.id,
    key: map.id,
  }));

  return (
    <BasePageLayout>
      <Toolbar>
        <Space>
          <Link to={generatePath(ROUTES.MAP, { id: NEW_MAP_ID })}>
            <Button type="primary">New</Button>
          </Link>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => mapsQuery.refetch()}
          />
          <SearchBar onSearch={onSearch.current} />
        </Space>
        <div style={{ float: 'right' }}>
          <AdminButton
            type="primary"
            disabled={selectedRowKeys.length === 0 || deleteMaps.isLoading}
            onClick={async () => {
              try {
                await deleteMaps.mutateAsync(selectedRowKeys);
                message.success('Items deleted');
              } catch (err) {
                handleError(err);
              }
            }}
          >
            Delete
          </AdminButton>
        </div>
      </Toolbar>
      <DataTable
        stateKey={MAPLIST_TABLE_KEY}
        columns={columns}
        dataSource={rows}
        rowSelection={rowSelection}
        loading={mapsQuery.isLoading}
      />
    </BasePageLayout>
  );
}
