import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { config } from './config';
import { makeHttpClient } from './http';

const http = makeHttpClient({ baseUrl: config.apiUrl });

let currentUser = null;

function login({ username, password }) {
  http.setAuth(username, password);
  return http.get('/admin/user');
}

export function useLoginMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(login, {
    onSuccess: data => {
      // for now is current user only stored in memory
      currentUser = data;
      queryClient.setQueryData(['currentUser'], data);
    },
  });
  return mutation;
}

export function useCurrentUserQuery() {
  return useQuery(['currentUser'], () => currentUser);
}

export function useMapsQuery() {
  return useQuery(['maps'], () => http.get('/admin/maps'));
}

export function useMapQuery(id, options) {
  return useQuery(['map', id], () => http.get(`/admin/maps/${id}`), {
    retry: false,
    ...options,
  });
}

export function useFileUploadUrl() {
  return file => http.post('/admin/upload-url', { filename: file.name });
}

export function useCreateMapMutation() {
  return useMutation(data => http.post('/admin/maps', data));
}

export function useUpdateMapMutation(id) {
  const queryClient = useQueryClient();
  return useMutation(data => http.put(`/admin/maps/${id}`, data), {
    onSuccess: () => queryClient.refetchQueries(['map', id]),
  });
}

function batchDeleteMaps(ids) {
  return Promise.all(ids.map(id => http.delete(`/admin/maps/${id}`)));
}

export function useBatchDeleteMapMutation() {
  const queryClient = useQueryClient();
  return useMutation(batchDeleteMaps, {
    onSuccess: () => queryClient.refetchQueries(['maps']),
  });
}

export function useCategoriesQuery() {
  return useQuery(['categories'], () => http.get('/admin/categories'));
}

export function useCreateCategoryMutation() {
  return useMutation(data => http.post('/admin/categories', data));
}
