import { Form, Layout, Typography } from 'antd';

import { MenuPoiFilter } from './MenuPoiFilter';
import { MenuPoisList } from './MenuPoisList';
import style from './sideMenu.module.css';

export const MapSideMenu = ({
  form,
  poisFilter,
  setPoisFilter,
  setCurrentPOI,
}) => {
  const pois = Form.useWatch('pois', form);

  return (
    <Layout.Sider trigger={null} width={230}>
      <div className={style.MenuChildrenWrapper}>
        <div className={style.MenuPartWrapper}>
          <Typography.Text strong style={{ color: 'white' }}>
            Number of POIs: {pois?.length ?? 0}
          </Typography.Text>
        </div>
        <MenuPoisList pois={pois} setCurrentPOI={setCurrentPOI} />
        <MenuPoiFilter
          pois={pois}
          poisFilter={poisFilter}
          setPoisFilter={setPoisFilter}
        />
      </div>
    </Layout.Sider>
  );
};
