import { useCopyImage } from './useCopyImage';

export const useCopyPOI = () => {
  const copyImage = useCopyImage();

  const copyPOI = async poi => {
    const imageCopy = poi.imageUrl && (await copyImage(poi.imageUrl));

    return {
      ...poi,
      categories: [...(poi.categories || [])],
      imageUrl: imageCopy,
    };
  };

  return copyPOI;
};
