import { createStore } from 'react-hooks-global-state';

const reducer = (state, action) => {
  switch (action.type) {
    case MAPLIST_TABLE_KEY:
      return { ...state, mapList: action.payload };
    case CATEGORIES_TABLE_KEY:
      return { ...state, categories: action.payload };
    default:
      console.log('default');
      return state;
  }
};

const initialState = {
  mapList: {
    page: 1,
    sort: { field: 'updatedAt', order: 'descend' },
  },
  categories: {
    page: 1,
    sort: { field: 'label', order: 'ascend' },
  },
};

const { dispatch, useStoreState } = createStore(reducer, initialState);

export const MAPLIST_TABLE_KEY = 'mapList';
export const CATEGORIES_TABLE_KEY = 'categories';

export { useStoreState as useTableState };

export const saveTableState = (stateKey, { pagination, sort }) => {
  dispatch({
    type: stateKey,
    payload: {
      page: pagination.current,
      sort: { field: sort.field, order: sort.order },
    },
  });
};
